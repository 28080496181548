/**
 * Created by preference on 2021/09/07
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 列表  */
  entitySaleGoodsDetailStatement_list: params => {
    return API.POST('api/entitySaleGoodsDetailStatement/list', params)
  },
  /**  品牌 */
  entitySaleGoodsDetailStatement_brand: params => {
    return API.POST('api/entitySaleGoodsDetailStatement/brand', params)
  },
  /**  分类 */
  entitySaleGoodsDetailStatement_category: params => {
    return API.POST('api/entitySaleGoodsDetailStatement/category', params)
  },
  /**   */
  entitySaleGoodsDetailStatement_excel: params => {
    return API.exportExcel('api/entitySaleGoodsDetailStatement/excel', params)
  },
}